<script lang="ts" setup>
import _ from 'lodash'
import type { strapiMedia } from '~/interfaces/strapi.interface'

const props = withDefaults(
  defineProps<{
    image: strapiMedia
    imageMobile?: strapiMedia
    align?: string
    transform?: string
    legendPosition?: string
    noTwic?: boolean
    noTwicSize?: string
    noTwicSizeMobile?: string
  }>(),
  {
    legendPosition: '',
    transform: '',
    align: 'start',
    noTwic: false,
  }
)

defineOptions({
  inheritAttrs: false,
})

const config = useRuntimeConfig()

const wealthTwicLink = ref('')

if (config.public.env === 'development' || config.public.env === 'staging') {
  wealthTwicLink.value = 'wealthLocal/'
} else if (config.public.env === 'preprod') {
  wealthTwicLink.value = 'wealthPreprod/'
} else {
  wealthTwicLink.value = ''
}

const legendPosition = computed(() => {
  if (props.legendPosition && props.legendPosition === 'gauche')
    return 'text-left'
  else if (props.legendPosition && props.legendPosition === 'centre')
    return 'text-center'
  else return 'text-right'
})

const getUrl = (type: string) => {
  const baseUrl = `${config.public.twicpics_domain}/${wealthTwicLink.value}`
  if (type === 'mobile') {
    const imageUrlMobile = `${_.has(props.imageMobile, 'data.attributes') ? props.imageMobile.data.attributes.hash : props.image.data.attributes.hash}${_.has(props.imageMobile, 'data.attributes') ? props.imageMobile.data.attributes.ext : props.image.data.attributes.ext}`
    return `${baseUrl}${imageUrlMobile}?twic=v1/cover-max=${props.noTwicSizeMobile ? props.noTwicSizeMobile : 800}`
  }

  const imageUrl = `${props.image.data.attributes.hash}${props.image.data.attributes.ext}`
  return `${baseUrl}${imageUrl}?twic=v1/cover-max=${props.noTwicSize ? props.noTwicSize : 1200}`
}
</script>

<template>
  <template v-if="noTwic || imageMobile">
    <div
      v-if="image && image.data && image.data.attributes.caption"
      class="flex"
      :class="`justify-${align}`"
    >
      <div class="flex w-full flex-col">
        <picture>
          <source media="(max-width: 767px)" :srcset="getUrl('mobile')" />
          <img
            v-bind="$attrs"
            class="block max-w-full"
            :alt="image.data.attributes.alternativeText"
            :src="getUrl('desktop')"
          />
        </picture>

        <span class="text-p4 text-grey-200" :class="[legendPosition]">
          {{ image.data.attributes.caption }}
        </span>
      </div>
    </div>
    <picture v-else-if="image">
      <source media="(max-width: 767px)" :srcset="getUrl('mobile')" />
      <img
        v-bind="$attrs"
        class="mx-auto block max-w-full"
        :alt="image.data.attributes.alternativeText"
        :src="getUrl('desktop')"
      />
    </picture>
  </template>
  <template v-else>
    <div
      v-if="image && image.data && image.data.attributes.caption"
      class="flex"
      :class="`justify-${align}`"
    >
      <div class="flex w-full flex-col">
        <img
          loading="lazy"
          v-bind="$attrs"
          class="block max-w-full"
          :alt="image.data.attributes.alternativeText"
          :data-twic-src="`media:/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}`"
          :data-twic-transform="`${transform}`"
          :src="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/output=preview`"
        />

        <span class="text-p4 text-grey-200" :class="[legendPosition]">
          {{ image.data.attributes.caption }}
        </span>
      </div>
    </div>

    <img
      v-else-if="image && image.data"
      v-bind="$attrs"
      loading="lazy"
      class="block max-w-full"
      :alt="image.data.attributes.alternativeText"
      :data-twic-src="`media:/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}`"
      :data-twic-transform="`${transform}`"
      :src="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/output=preview`"
    />
  </template>
</template>
